<template>
  <div class="login">
    
    <div class="content">
      <div class="title"><img height="40" src="../assets/img/Index_JobSeeker/logo.png" alt=""></div>
      <div class="from">
        <h3>找回密码</h3>
        <transition-group name="el-zoom-in-center" tag="div">
        <div class="bd" key="jobphone">
            <div class="item"><input type="text" maxlength="11" class="phone" placeholder="请输入手机号" v-model="job.tel"></div>
            <div class="item"><input type="text" @keyup.enter="login" class="code" placeholder="请输入验证码" v-model="job.shortCaptcha">
              <div class="yzm" v-show="shortCode" @click="shortCaptcha">获取验证码</div>
              <div class="yzm send" v-show="!shortCode" @click="shortCaptcha">{{count}}s后重新获取</div>
            </div>
            <div class="item" v-if="job.loginType==2"><input type="text" class="name" placeholder="请输入账号" v-model="job.account"></div>
            <div class="item"><input type="password" class="password" placeholder="请输入新密码" v-model="job.password"></div>
            <div class="item"><input type="password" class="password" placeholder="请确认新密码" v-model="job.newPassword"></div>
        </div>
        </transition-group>
        <div class="tips"><span>*</span>密码至少6位，包含字母和数字</div>
        <div class="btn" @click="login">
          <img src="../assets/img/login/icon-tj.png" alt="" srcset="">
        </div>
      </div>
      
    </div>
  </div>
</template>
<script>
import { rest, shortCaptcha } from '@/api/login'
export default {
  name: "Login",
  data(){
    return {
      count: 60,
      shortCode: true,
      job: {
        newPassword: '',
        password:'',
        tel: '',
        shortCaptcha: '',
        loginType: 1,
        account: ''
      }
    }
  },
  created(){
    this.job.loginType = this.$route.query.type
  },
  components: {
  },
  methods:{
    // 短信验证码
    shortCaptcha(){
      this.shortCode = false
      let params = {}
      if(!this.job.tel){
        this.$message.warning('手机不能为空！')
        this.shortCode = true;
        return
      }
      params = {
        tel: this.job.tel
      }
      shortCaptcha(params).then(res => {
        console.log(res)
        this.$message.success('短信发送成功')
        this.count = 60;
        this.shortCode = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= 60) {
              this.count--;
          } else {
              this.shortCode = true;
              clearInterval(this.timer);
              this.timer = null;
          }
      }, 1000)
      }).catch(err => {
        console.log(err)
        this.shortCode = true;
      })
    },
  
    // 登录
    login() {
      let params = {}
        if(!this.job.tel){
          this.$message.warning('手机号不能为空！')
          return
        }
        if(!this.job.shortCaptcha){
          this.$message.warning('短信验证码不能为空！')
          return
        }
        if(!this.job.password){
          this.$message.warning('密码不能为空！')
          return
        }
        if(this.job.password.length<6 || (!/\d/.test(this.job.password) || !/[a-z|A-Z]/.test(this.job.password))){
          this.$message.warning('密码至少6位，必须包含字母和数字！')
          return
        }
        if(this.job.newPassword != this.job.password){
          this.$message.warning('两次密码不一致！')
          return
        }
        params = {
          ...this.job
        }
      rest({...params}).then(() => {
        this.$message.success('密码设置成功！')
        this.$router.replace('/login')
      })
    }
  }
};
</script>
<style scoped lang="scss">
.login{
  height: 100vh;
  background: transparent url('../assets/img/login/bg.png') no-repeat left top;
  background-size: 100% 100vh;
  .title{
    margin-bottom: 20px;
  }
  .content{
    width: 500px;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
    text-align: left;
    .from{
      text-align: center;
      background: #fff;
      padding: 20px 60px 10px;
      border-radius: 4px 4px 4px 4px;
    }
    h3{
      font-size: 26px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 32px;
    }
    .tag{
      color: #999999;
      margin: -16px 0 32px;
      a{
        color: #333333;
      }
    }
    .bd{
      margin-top: 32px;
      &.el-zoom-in-center-leave-active{
        display: none;
      }
    }
    .tips{
      overflow: hidden;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      text-align: left;
      margin-bottom: 20px;
      cursor: pointer;
      margin-top: -3px;
      span{
        color: red;
      }
    }
    .item{
      margin-bottom: 16px;
      position: relative;
      img{
        width: 115px;
        height: 50px;
      }
      input{
        height: 50px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #C4C7D3;
        line-height: 50px;
        width: 100%;
        padding: 0 8px;
        &.name{
          background: transparent url('../assets/img/login/icon-user.png') no-repeat 8px center!important;
          background-size: 21px 21px!important;
          text-indent: 30px;
        }
        &.phone{
          background: transparent url('../assets/img/login/icon-phone.png') no-repeat 8px center!important;
          background-size: 21px 21px!important;
          text-indent: 30px;
        }
        &.password{
          background: transparent url('../assets/img/login/icon-password.png') no-repeat 8px center!important;
          background-size: 21px 21px!important;
          text-indent: 30px;
        }
        &.code{
          background: transparent url('../assets/img/login/icon-yzm.png') no-repeat 8px center!important;
          background-size: 21px 21px!important;
          text-indent: 30px;
        }
        &.license{
          background: transparent url('../assets/img/login/icon-name.png') no-repeat 8px center!important;
          background-size: 21px 21px!important;
          text-indent: 30px;
        }
      }
      .yzm{
        position: absolute;
        right: 0;
        font-size: 14px;
        color: #0780E6;
        top: 0;
        height: 50px;
        text-align: center;
        line-height: 50px;
        width: 110px;
        cursor: pointer;
        &::after{
          content: '';
          position: absolute;
          left: 0;
          height: 24px;
          width: 1px;
          background: #eee;
          top: 13px;
        }
        &.send{
          color: #999;
        }
      }
      &.code{
        input{
          width: 265px;
          border-radius: 4px 0 0 4px;
        }
        img{
          float: right;
        }
      }
    }
    .btn{
      img{
        width: 380px;
        height: 80px;
        cursor: pointer;
        &:hover{
          opacity: .8;
        }
      }
    }
  }
}
.tab-catalog-00{
  font-size: 18px;
  font-weight: normal;
  border-bottom: 1px solid #CCCCCC;
  margin: 0 56px;
  overflow: hidden;
  a{
    color: #0780E6;
    padding-bottom: 10px;
    float: left;
    width: 50%;
    text-align: left;
  }
  a:last-child{
    float: right;
    text-align: right;
  }
  a.atthis{
    border-bottom: 3px solid #3580DF;
    margin-bottom: -1px;
  }
}
</style>
